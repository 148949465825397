<template>
  <div class="marketing">
    <!-- 顶部导航栏 -->
    <topNav></topNav>

    <!-- 背景开始 -->
    <div class="top-bg">
      <div class="top-bg-content">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>全场景金融营销解决方案</div>
            <div>
              用多种营销形式，接入衣食住行等生活场景，加速金融机构数字化转型
            </div>
          </div>
        </div>
      </div>
      <div class="top-bg-content-phone">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>数字营销解决方案专家</div>
            <div>
              洞察市场先机，创造品牌持久推动力
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 背景结束 -->

    <!-- pc 中间部分开始 -->
    <div class="sectionAll">
      <!-- 部分1开始 -->
      <div class="section-1">
        <h2>智慧营销必备工具</h2>
        <p class="subtitle">活跃粉丝 | 品牌传播 | 裂变涨粉 | 极速获客</p>
        <ul class="tool-wrap">
          <li
            class="tool-item"
            v-for="(v, i) in list.toolList"
            :key="i"
            :class="{ 'item-bg2move': v.id == list.toolNum }"
            @mouseenter="enter(i)"
            @mouseleave="leave()"
          >
            <div
              class="item-img"
              :style="{
                background: 'url(' + v.bg + ') no-repeat',
              }"
            ></div>
            <div
              class="item-img2"
              :style="{
                background: 'url(' + v.bg2 + ') no-repeat',
              }"
            ></div>
          </li>
        </ul>
      </div>
      <!-- 部分1结束 -->

      <!-- 部分2开始 -->
      <div class="section-2"></div>
      <!-- 部分2结束 -->
    </div>
    <!-- pc 中间部分结束 -->

    <!-- 移动 中间部分开始 -->
    <div class="phoneAll">
      <div class="section-1">
        <h2>使用优越营销为企业带来</h2>
        <p>
          <span>
            从品牌的发展策略出发，致力于打造国内极具行业影响力的数字营销智库，是服务于全行业产业链条的专属数字平台，让营销由难变易、化繁为简，使品牌有所作为。
          </span>
        </p>
      </div>
      <!--基础存放容器-->
      <div class="swiper-container">
        <!-- 需要进行轮播的部分 -->
        <div class="swiper-wrapper">
          <!-- 每个节点 -->
          <div class="swiper-slide" v-for="(v, i) in list.phoneList" :key="i">
            <img :src="v.bg" alt="图片1" />
          </div>
        </div>
      </div>

      <div class="section-2">
        <h2>精益求精，品销合一</h2>
        <div class="two-market">
          <img src="../assets/image/marketing//btmLogo.png" alt="" />
          <ul>
            <li class="two-item" v-for="(v, i) in list.DList" :key="i">
              <img :src="v.logo" alt="" />
              <div class="two-cell">
                <h3>{{ v.title }}</h3>
              </div>
              <ul class="two-point">
                <li class="point-text">{{ v.p1 }}</li>
                <li class="point-text">{{ v.p2 }}</li>
                <li class="point-text">{{ v.p3 }}</li>
                <li class="point-text">{{ v.p4 }}</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 移动 中间部分结束 -->
    <Bottom></Bottom>
  </div>
</template>

<script>
import "../assets/less/marketing.less";
import alllist from "../assets/js/index";
import list from "../assets/js/marketing";
import "../../node_modules/swiper/dist/css/swiper.css";
import Swiper from "../components/swiper";
import topNav from "../components/topNav";
import Bottom from "../components/Bottom";
export default {
  data() {
    return {
      // *全部列表
      alllist,
      list,
    };
  },
  components: {
    topNav,
    Bottom,
  },
  mounted() {
    this.initSwiper();
    //刷新一次
    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
  },
  methods: {
    initSwiper() {
      new Swiper(".swiper-container", {
        //设置轮播的循环方式
        loop: true,

        //设置自动播放间隔时间
        autoplay: 2000,

        // 轮播效果,默认为平滑轮播
        effect: "slide",

        // 用户中断轮播后续播
        autoplayDisableOnInteraction: false,
      });
    },
    // 鼠标移入
    enter(index) {
      if (index + 1 == this.list.toolNum) {
        return;
      }
      this.list.toolNum = index + 1;
    },
    leave() {
      this.list.toolNum = 0;
    },

    //*切换激活状态
    toggle(i) {
      if (i == this.alllist.proindex) {
        return;
      }
      this.alllist.proindex = i;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .swiper-slide-active > img {
  width: 90% !important;
  margin-top: -8rem;
}
.swiper-container {
  width: 90rem;
  height: 90rem;
  margin-top: 30rem;
  overflow: unset;
}

.swiper-slide img {
  width: 70%;
  object-fit: cover;
  transition: all 0.5s ease;
}

.swiper-slide img {
  box-shadow: 0 7px 16px 0 rgb(221 221 221);
}
</style>
